import React, { useEffect, useState } from 'react';

import classNames from 'classnames';

import {
  type AdnPlacement,
  AdPlacement,
  AdServer,
  Brand,
  type ClientAdPlacement,
  debugLog,
  DeviceType,
  type LayoutType,
  PageType,
  type RecommendationCategories
} from '@schibsted-nmp/advertising-shared';
import { $config } from '@client/core/atoms/config.js';
import { $placementsMap } from '@client/core/atoms/placements.js';
import { useInScreen } from '@client/core/hooks/index.js';
import { observeFullWidthAd } from '@client/core/utils/dom/handleAdsOverlap/observeFullWidthAd.js';
import { useInitiateAdnUnit } from '@client/adnuntius/AdUnit/useInitiateAdnUnit.js';
import {
  forceBatchRequestOnSidePlacements,
  setAdnamiPlacement
} from '@client/core/utils/dom/handleStickySkyscrapers.js';
import type { InViewSettings } from '@client/core/utils/getInViewSettingsByPlacementId.js';

export type AdnAdUnitProps = {
  placement: ClientAdPlacement<AdnPlacement>;
  containerId: string;
  initialLayoutType?: LayoutType;
  categories?: RecommendationCategories;
  inViewSettings?: InViewSettings | undefined;
};
function AdnAdUnit({ containerId, placement, inViewSettings }: AdnAdUnitProps) {
  const { brand, deviceType, vertical, pageType } = $config.get() ?? {};
  const { placementId } = placement;
  const ref = React.createRef<HTMLDivElement>();
  const { isIntersecting } = useInScreen({ ref });

  const [clientPlacement, setClientPlacement] =
    useState<ClientAdPlacement<AdnPlacement>>(placement);
  const { creativeType } = clientPlacement;

  useEffect(() => {
    const unsubscribe = $placementsMap.subscribe((placements) => {
      const clientPlacement =
        typeof placements === 'object' && placements[placement.placementId];
      if (!clientPlacement) {
        return;
      }
      if (clientPlacement.adServer.type === AdServer.Adn) {
        setClientPlacement(clientPlacement as ClientAdPlacement<AdnPlacement>);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [placement]);

  useInitiateAdnUnit({
    placement: clientPlacement,
    containerId,
    ref,
    inViewSettings
  });

  useEffect(() => {
    if (creativeType === 'adnami') {
      setAdnamiPlacement(placementId);
      forceBatchRequestOnSidePlacements(placementId);
    } else if (
      (brand === Brand.Tori || brand === Brand.Dba) &&
      placementId === AdPlacement.AdvtMiddle2.id &&
      deviceType === DeviceType.Desktop
    ) {
      observeFullWidthAd(placementId, () => {
        debugLog('Full width ad detected');
        setAdnamiPlacement(placementId);
      });
    }
  }, [creativeType, placementId]);

  // Takeover ad styling
  const isTakeoverAd = creativeType === 'takeover';
  const takeoverIsIntersecting = isTakeoverAd && isIntersecting;

  useEffect(() => {
    if (typeof document !== 'undefined') {
      const { body } = document;
      if (takeoverIsIntersecting) {
        body.classList.add('advt-takeover-ad');
      } else {
        body.classList.remove('advt-takeover-ad');
      }
    }
  }, [takeoverIsIntersecting]);

  // Sponsored Content & Native ad styling
  const isNativeAdStyles =
    creativeType === 'native' || creativeType === 'sponsored-content';

  const cardNativeAdVertical = ['realestate', 'travel'].includes(
    vertical ?? ''
  );

  const isRecommendationFrontAd =
    isNativeAdStyles &&
    placementId.includes('recirc') &&
    pageType === PageType.Front;

  const cardNativeAd =
    isNativeAdStyles && (cardNativeAdVertical || isRecommendationFrontAd);

  return (
    <div
      ref={ref}
      className={classNames('flex w-full advt-placement-container', {
        'is-intersecting': isIntersecting,
        'h-full': !isRecommendationFrontAd
      })}
    >
      <div
        className={classNames('mx-auto h-full advt-placement-item', {
          'w-full':
            creativeType === 'native' || creativeType === 'sponsored-content',
          'shadow-s hover:shadow-m border border-gray-200 rounded-8':
            cardNativeAd || creativeType === 'sponsored-content'
        })}
        id={placementId}
        data-is-adnami={creativeType === 'adnami'}
      />
    </div>
  );
}

export default AdnAdUnit;
